$(function(){
  $('.switch-lang-link-ch, .switch-lang-link').on('click', function(event) {
      event.preventDefault();
      return switchLanguageCH( $(this).attr('data-mp-lang') );
  });

  function switchLanguageCH(lang) {
      var domains = { 'de_CH' : '/', 'fr_CH' : '/fr-SWI/' };
      if (lang != generic.cookie('LOCALE')){
          var path = window.location.pathname.replace(/\/fr\-SWI/, "");
          document.cookie = 'LOCALE=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
          generic.cookie('LOCALE', lang, {path : '/'});

          if (path.indexOf(".tmpl") > 0 || path.indexOf(".tmpl") > 0) {
            domains[lang] = '';
          }
          try {
            if (eval("Drupal.settings.elc_common." + lang + "_url") != undefined && path != "/") {
              path = eval("Drupal.settings.elc_common." + lang + "_url");
            }
          }
          catch (error) {}

          if (path == "/") {
            path = "";
          }

          var pathVal;
          if ($(".section-victoria-beckham").length > 0) {
            pathVal = path + "#/6";
          } else {
            pathVal = path;
          }
          var site_path = window.location.host + domains[lang] + pathVal;
          site_path = site_path.replace(/\/+/g, '/');
          var url = window.location.protocol + '//' + site_path;
          var full_url = window.location.href;
          var appends = '', full_url_parts;
          if(full_url.indexOf("#") > 0) {
            full_url_parts = full_url.split("#");
            appends = "#" + full_url_parts[1];
          } else if (full_url.indexOf("?") > 0) {
            full_url_parts = full_url.split("?");
            appends = "?" + full_url_parts[1];
          }
          window.location.href = url + appends;
      }
  }

  $(document).ready(function () {
    var path = window.location.pathname;
    var pathVal;
    var pathArray = path.split('/');
    if ($(".section-victoria-beckham").length > 0) {
      pathVal = window.location.pathname + "#/6";
    } else {
      pathVal = window.location.pathname;
    }
    if (generic.cookie('LOCALE') == 'fr_CH' && pathArray[1] != 'fr-SWI' && path.indexOf(".tmpl") == -1 && path.indexOf(".tmpl") == -1) {
        var full_url = window.location.href;
        var appends = '', full_url_parts;
        if (full_url.indexOf("#") > 0) {
          full_url_parts = full_url.split("#");
          appends = "#" + full_url_parts[1];
        } else if (full_url.indexOf("?") > 0) {
          full_url_parts = full_url.split("?");
          appends = "?" + full_url_parts[1];
        }
      window.location.href = window.location.protocol + '//' + window.location.host + '/fr-SWI' + pathVal + appends;
    }
  });
});
